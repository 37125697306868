import useTranslate from 'hooks/useTranslate'
import { ProductItemModel } from 'types/models'

type Props = {
  item: ProductItemModel
  isDescriptionHidden?: boolean
  viewingSelf: boolean
}

type ItemDescription = { title: string; subtitle: string } | null

const useItemDescription = (props: Props): ItemDescription => {
  const { item, isDescriptionHidden, viewingSelf } = props

  const translate = useTranslate()

  if (isDescriptionHidden) return null

  const getSellerDescription = () => {
    if (item.isDraft) {
      return { title: '', subtitle: '' }
    }

    const title =
      item.viewCount === undefined
        ? item.sizeTitle
        : translate('item.view_count', { count: item.viewCount }, { count: item.viewCount })

    const subtitle = translate(
      'item.favourite_count',
      { count: item.favouriteCount },
      { count: item.favouriteCount },
    )

    return { title, subtitle }
  }

  const getBuyerDescription = () => ({
    title: item.brandTitle === '' ? item.title : item.brandTitle,
    subtitle: item.sizeTitle === '' ? item.status : item.sizeTitle,
  })

  return viewingSelf ? getSellerDescription() : getBuyerDescription()
}

export default useItemDescription
