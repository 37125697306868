'use client'

import { ReactNode } from 'react'
import { Cell, Spacer, Text } from '@vinted/web-ui'

import { getTestId } from 'libs/utils/testId'

import ItemBoxFavourite from '../ItemBoxFavourite'

export type RenderFavouriteArgsType = {
  favourite: ComponentProps<typeof ItemBoxFavourite>
}

type Props = {
  renderPriceBreakdown?: ReactNode
  testId?: string
  bumpText?: ReactNode
  description?: { title: ReactNode; subtitle?: ReactNode } | null
  price: string
  oldPrice?: string | null
}

const InformationBreakdown = (props: Props) => {
  const { renderPriceBreakdown, testId, bumpText, description, price, oldPrice } = props

  const renderBumpedText = () => (
    <Text
      text={bumpText}
      theme="amplified"
      type={Text.Type.Caption}
      testId={getTestId(testId, 'bump-text')}
      as="p"
    />
  )

  const renderAskingPrice = () => (
    <div className="new-item-box__title" data-testid={getTestId(testId, 'title-container')}>
      <div className="title-content">
        <Text
          text={price}
          type={Text.Type.Caption}
          theme="muted"
          testId={getTestId(testId, 'price-text')}
          as="p"
        />
        {oldPrice ? (
          <>
            <Spacer orientation={Spacer.Orientation.Vertical} size={Spacer.Size.Small} />
            <Text
              text={oldPrice}
              type={Text.Type.Caption}
              theme="muted"
              strikethrough
              truncate
              testId={getTestId(testId, 'old-price-text')}
              as="p"
            />
          </>
        ) : null}
      </div>
    </div>
  )

  const renderDescription = () => {
    if (!description) return null

    return (
      <Cell styling={Cell.Styling.Tight} testId={getTestId(testId, 'description')}>
        <div className="new-item-box__description">
          <Text
            text={description.title}
            type={Text.Type.Caption}
            testId={getTestId(testId, 'description-title')}
            as="p"
            truncate
          />
          {bumpText && renderBumpedText()}
        </div>
        <div className="new-item-box__description">
          <Text
            text={description.subtitle}
            type={Text.Type.Caption}
            testId={getTestId(testId, 'description-subtitle')}
            as="p"
          />
        </div>
      </Cell>
    )
  }

  return (
    <div>
      <div className="u-flexbox u-align-items-flex-start u-ui-padding-bottom-regular">
        <div className="u-min-width-none u-flex-grow">{description && renderDescription()}</div>
      </div>
      <div>
        {renderAskingPrice()}
        {renderPriceBreakdown}
      </div>
    </div>
  )
}

export default InformationBreakdown
