'use client'

import { Spacer } from '@vinted/web-ui'

import ServiceFeeIncludedTitle from 'components/ServiceFeeIncludedTitle'
import useSession from 'hooks/useSession'
import { ProductItemModel } from 'types/models'
import { getTestId } from 'libs/utils/testId'

type Props = {
  item: ProductItemModel
  testId: string
}

const ItemPriceBreakdown = ({ item, testId }: Props) => {
  const { user: currentUser } = useSession()
  const { serviceFee, id, price, totalItemPrice, user, priceWithDiscount } = item

  const isViewingSelf = currentUser?.id === user.id
  const showPriceBreakdownChanges = !!serviceFee && !isViewingSelf

  if (!showPriceBreakdownChanges) return null

  return (
    <div data-testid={getTestId(testId, 'breakdown')}>
      <ServiceFeeIncludedTitle
        itemId={id}
        itemPrice={priceWithDiscount || price}
        serviceFee={serviceFee}
        totalItemPrice={totalItemPrice}
        isSellerBusiness={!!user.isBusiness}
        shouldTrimTitle
        shouldMinimiseTitle
      />
      <Spacer size={Spacer.Size.Small} />
    </div>
  )
}

export default ItemPriceBreakdown
